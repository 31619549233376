<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="overlay_show"
      opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <b>ตั้งค่าเวลาใช้งาน</b>
            <div style="margin-left: 80%; margin-top: -25px">
              <b-button variant="primary" @click="save()">
                <span>บันทึก</span>
              </b-button>
            </div>
            <b-row>
              <b-col md="6">
                <b-form-group label="ชื่อ">
                  <b-form-input v-model="name" type="text" placeholder="ชื่อ" />
                  <b-alert v-if="check.name == true" variant="danger" show>
                    <span>Please Enter</span>
                  </b-alert>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="รายละเอียด">
                  <b-form-input
                    v-model="detail"
                    placeholder="รายละเอียด"
                    type="text"
                    maxlength="45"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" xl="4" lg="4">
                <b-form-group>
                  <h5>เริ่มวัน</h5>
                  <flat-pickr v-model="date_start" class="form-control" />
                </b-form-group>
                <b-alert
                  v-model="check.date_start"
                  v-height-fade.appear
                  variant="danger"
                  dismissible
                  class="mb-0"
                >
                  <div class="alert-body">ใส่วันเริ่ม</div>
                </b-alert>
              </b-col>
              <b-col md="4" xl="4" lg="4">
                <b-form-group>
                  <h5>เวลา</h5>
                  <flat-pickr
                    v-model="time_start"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                    }"
                  />
                </b-form-group>
                <b-alert
                  v-model="check.time_start"
                  v-height-fade.appear
                  variant="danger"
                  dismissible
                  class="mb-0"
                >
                  <div class="alert-body">ใส่เวลาเริ่ม</div>
                </b-alert>
              </b-col>
              <b-col md="4" xl="4" lg="4" style="margin-top: 2em">
                <div>
                  ไม่มีวันสิ้นสุด
                  <b-form-checkbox
                    checked="true"
                    name="check-button"
                    switch
                    v-model="day"
                    inline
                  />
                </div>
              </b-col>
            </b-row>
            <br />
            <b-row v-if="day == 'false' || day == false">
              <b-col md="4" xl="3" lg="3">
                <b-form-group>
                  <h5>สิ้นสุดวัน</h5>
                  <flat-pickr v-model="date_end" class="form-control" />
                </b-form-group>

                <b-alert
                  v-model="check.date_start"
                  v-height-fade.appear
                  variant="danger"
                  dismissible
                  class="mb-0"
                >
                  <div class="alert-body">ใส่วันสิ้นสุด</div>
                </b-alert>
              </b-col>
              <b-col md="4" xl="3" lg="3">
                <b-form-group>
                  <h5>เวลา</h5>
                  <flat-pickr
                    v-model="time_end"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                    }"
                  />
                </b-form-group>
                <b-alert
                  v-model="check.time_start"
                  v-height-fade.appear
                  variant="danger"
                  dismissible
                  class="mb-0"
                >
                  <div class="alert-body">ใส่เวลาสิ้นสุด</div>
                </b-alert>
              </b-col>
            </b-row>
            <b-alert
              v-model="check.time_error"
              v-height-fade.appear
              variant="danger"
              dismissible
              class="mb-0"
            >
              <div class="alert-body">เวลาผิดพลาด</div>
            </b-alert>
            <b-alert
              v-model="check.date_error"
              v-height-fade.appear
              variant="danger"
              dismissible
              class="mb-0"
            >
              <div class="alert-body">วันที่ผิดพลาด</div>
            </b-alert>
            <br />

            <div
              @click="set_img = true"
              style="float: left; margin-right: 50px"
            >
              <b-avatar variant="light-danger" rounded>
                <feather-icon icon="ImageIcon" size="38" />
              </b-avatar>
              <br />รูปภาพ
            </div>
            <div @click="$refs.file_video.click()">
              <b-avatar variant="light-danger" rounded>
                <feather-icon icon="VideoIcon" size="38" />
              </b-avatar>
              <br />วีดีโอ
            </div>

            <br /><br />
            <input
              type="file"
              style="display: none"
              ref="file_video"
              @change="onFileChangeVIDEO"
              accept="video/*"
            />
            <br /><br /><br />
          </b-card>
        </b-col>
      </b-row>

      <previwe ref="datapreviwe"></previwe>
    </b-overlay>
    <b-modal
      v-model="set_img"
      id="modal-center"
      centered
      size="lg"
      title="เลือกประเภทการ์ด"
      ok-only
      ok-variant="danger"
      ok-title="ยกเลิก"
      no-close-on-backdrop
      hide-footer
      hide-header-close
    >
      <div>
        <b-overlay
          id="overlay-background"
          :show="overlay_show"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <b-form-group>
            <b-form-file
              v-model="file"
              placeholder="รูปภาพ"
              type="file"
              accept=".jpeg,.png,.jpg"
              :hidden="true"
              @change="onFileChange"
              size="sm"
            />

            <cropper
              style="height: 470px"
              ref="cropper"
              class="twitter-cropper"
              background-class="twitter-cropper__background"
              foreground-class="twitter-cropper__foreground"
              image-restriction="stencil"
              :stencil-size="stencilSize"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 1,
                previewClass: 'twitter-cropper__stencil',
              }"
              :transitions="false"
              :canvas="true"
              :debounce="false"
              :default-size="defaultSize"
              :min-width="170"
              :min-height="170"
              :src="pictrue"
              @change="onChange"
            />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button type="submit" v-if="pictrue" variant="" @click="crop()">
                ใช้ </b-button
              >&nbsp;&nbsp;&nbsp;
              <b-button type="submit" variant="danger" @click="set_img = false">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BListGroup,
  BListGroupItem,
  VBModal,
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
  BFormCheckbox,
  BFormRadio,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { heightFade } from "@core/directives/animations";
import previwe from "./preview.vue";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import store from "@/store/index";
import Navigation from "./Navigation";
export default {
  components: {
    Navigation,
    Cropper,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BAlert,
    BFormCheckbox,
    BFormRadio,
    previwe,
    flatPickr,
    BFormFile,
    BOverlay,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      date_start: "",
      time_start: "",
      date_end: "",
      time_end: "",
      pictrue: "",
      detail: "",
      name: "",
      day: false,
      set_img: false,
      overlay_show: false,
      totalRows: "",
      file: "",
      file_video: "",
      type: "",
      rows: [],
      check: {
        date_start: false,
        time_start: false,
        date_end: false,
        time_error: false,
        date_error: false,
        name: false,
      },
      zoom: 0,
      id: 0,
      url: "",
      dir: "ltr",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
  mounted() {
    this.loaddata_edit();
  },

  watch: {
    day: {
      handler: function (val, oldVal) {
        console.log("oldVal :>> ", oldVal);
        console.log("val :>> ", val);
        if (val === true || val === 'true') {
          this.date_end = "3022-12-31";
          this.time_end = "23:59:59";
        } else {
          this.date_end = this.date_start;
          this.time_end = this.time_end;
        }
      },
      deep: true,
    },
    detail: {
      handler: function (val, oldVal) {
        console.log("val :>> ", val);
        this.$refs.datapreviwe.set_detail(val);
      },
      deep: true,
    },
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      console.log("file", this.file);
      const image = e.target.files[0];
      this.pictrue = URL.createObjectURL(image); ///show
      this.type = "jpg";
    },
    onFileChangeVIDEO(e) {
      this.file_video = e.target.files[0];

      var ext = this.file_video.name.split(".")[1];
      this.type = ext;
      this.url = "video";
      console.log(" e.target.result :>> ", e.target.files[0]);
      const url = URL.createObjectURL(e.target.files[0]);
      this.$refs.datapreviwe.set_Video(url);
    },
    loaddata_edit() {
      // this.overlay_show = true;
      const params = {
        id: this.$route.query.id,
      };
      console.log("params :>> ", params);
      api
        .post("edit_quickPosts", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);

          this.name = response.data.name;
          this.detail = response.data.detail;
          this.type = response.data.type;
          this.url = response.data.url;
          this.date_start = response.data.date_start;
          this.time_start = response.data.time_start;
          this.date_end = response.data.date_end;
          this.time_end = response.data.time_end;
          this.day = response.data.day_end;
          this.id = response.data.id;
          if (this.type === "jpg") {
            this.$refs.datapreviwe.set_Image(this.url);
          } else {
            this.$refs.datapreviwe.set_Video(this.url);
          }
          this.overlay_show = false;
        });
    },

    save() {
      console.log("this.time_end :>> ", this.time_end);
      console.log("this.date_end :>> ", this.date_end);
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "ตรวจสอบข้อมูล",
            icon: "CheckIcon",
            text: "กำลังตรวจสอบข้อมูล",
            variant: "warning",
          },
        },
        {
          timeout: 2000,
        }
      );
      if (
        this.name &&
        this.url &&
        this.date_start &&
        this.time_start &&
        this.date_end &&
        this.time_end
      ) {
        if (Date.parse(this.date_start) === Date.parse(this.date_end)) {
          if (
            parseInt(this.time_start.substring(0, 2)) >
            parseInt(this.time_end.substring(0, 2))
          ) {
            this.check.time_error = true;
          } else {
            this.insert();
          }
        }
        if (Date.parse(this.date_start) < Date.parse(this.date_end)) {
          this.insert();
        }
        if (Date.parse(this.date_start) > Date.parse(this.date_end)) {
          this.check.date_error = true;
        }
      } else {
        this.check.show_id = true;
      }
      if (!this.date_start) {
        this.check.date_start = true;
      }
      if (!this.date_end) {
        this.check.date_end = true;
      }
      if (!this.time_start) {
        this.check.time_start = true;
      }
      if (!this.time_end) {
        this.check.time_end = true;
      }
      if (!this.name) {
        this.check.name = true;
      }
    },
    insert() {
      this.overlay_show = true;
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("file", this.file);
      formData.append("file_video", this.file_video);
      formData.append("name", this.name);
      formData.append("detail", this.detail);
      formData.append("date_start", this.date_start);
      formData.append("time_start", this.time_start);
      formData.append("time_start", this.time_start);
      formData.append("time_end", this.time_end);
      formData.append("date_end", this.date_end);
      formData.append("day_end", this.day);
      formData.append("url", this.url);
      formData.append("type", this.type);
      formData.append("store_id", localStorage.getItem("store_id"));

      api
        .post("update_quickPosts", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.overlay_show = false;
          console.log("response.data :>> ", response.data);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "เพิ่มข้อมูล",
                icon: "SaveIcon",
                text: "บันทึกข้อมูลของคุณเรียบร้อย",
                variant: "success",
              },
            },
            {
              timeout: 4000,
            }
          );
          setTimeout(() => {
            this.$router.push({
              path: "/liff/quickPosts",
            });
          }, "2000");
        });
    },

    crop() {
      this.overlay_show = true;
      const result = this.$refs.cropper.getResult();
      console.log("result :>> ", result);
      this.resizeImage(result.canvas.toDataURL("image/jpg"), 1000, 1000).then(
        (base64) => {
          const params = { pictrue: base64 };
          api
            .post("convert_base64_decode", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.overlay_show = false;
              console.log("response.data :>> ", response.data);
              this.url = response.data;
              this.$refs.datapreviwe.set_Image(response.data);
              this.set_img = false;
            })
            .catch((err) => {});
        }
      );
    },

    resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
      return new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          const MAX_WIDTH = maxWidth;
          const MAX_HEIGHT = maxHeight;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL());
        };
      });
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 48,
        height: Math.min(boundaries.height, boundaries.width) - 48,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
